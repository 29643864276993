<template>
    <div>
        <HeaderLight />
        <Good />
    </div>
</template>

<script>

import Good from '../components/Good.vue'
import HeaderLight from '../components/HeaderLight.vue'

export default {
    components: {
        Good, HeaderLight
    },
}
</script>

